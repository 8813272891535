
import { get_owner_type } from "~/plugins/common-fns.js";
import { utils, writeFile } from "xlsx";
import { sortByAddress } from "~/helpers/sort-helpers.js";
import { getAddressAsShortString } from "~/helpers/address-helpers.js";
import { getOwnerName } from "~/helpers/owner-helpers.js";

export default {
  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sortFunciton: this.sortByOwnership,
      searchTerm: "",
      isDesc: false,
      headers: [
        {
          name: this.$t("OWNERS_OWNER"),
          active: true,
          sortFnc: this.sortByOwner,
        },
        {
          name: this.$t("OWNERS_OWNERSHIP"),
          active: false,
          sortFnc: this.sortByOwnership,
        },
        {
          name: this.$t("OWNERS_OWNED"),
          active: false,
          sortFnc: this.sortByOwned,
        },
      ],
    };
  },

  computed: {
    valid_sorted_owners_for_table() {
      let arr = this.property.condo.owners
        ?.filter((x) => x.statusText === "gældende")
        .map((x) => {
          return { ...x, units: [this.property.condo] };
        });
      if (arr == undefined || arr == null || arr.length == 0) {
        return [];
      }

      let filteredOwners = arr.filter((owner) => {
        if (this.getOwnerName(owner).toLowerCase().includes(this.searchTerm.toLowerCase())) {
          return owner;
        } else if (
          owner.units &&
          owner.units.some((unit) => {
            if (!unit.unit) {
              return null;
            }
            return getAddressAsShortString(unit.unit.address).toLowerCase().includes(this.searchTerm.toLowerCase());
          })
        ) {
          return owner;
        } else return null;
      });

      let orderedStories = this.customSort(filteredOwners, this.sortFunciton, this.isDesc);
      return orderedStories;
    },
  },

  methods: {
    get_owner_type,

    downloadOwners() {
      this.amplitudeLogEvent();

      let data = this.convertOwners();
      if (!data) return;
      let filename = "Owners_";
      var wb = utils.book_new();
      var ws = utils.json_to_sheet(data);
      utils.book_append_sheet(wb, ws, "Owners");
      writeFile(wb, filename + this.$moment(new Date()).format("YYYY-MM-DD-HH-mm") + ".xlsx");
    },

    convertOwners() {
      if (!this.valid_sorted_owners_for_table) return null;

      let rows = [];
      //TODO
      this.valid_sorted_owners_for_table.forEach((owner) => {
        if (!owner.units) {
          let res = new Object();
          res["Owner"] = this.getOwnerName(owner);
          res["Ownership"] = get_owner_type.call(this, owner.ownershipCode);
          res["The owned"] = "The main property";
          res["Share"] = "-";
          rows.push(res);
        } else {
          owner.units.forEach((unit) => {
            let res = new Object();
            res["Owner"] = this.getOwnerName(owner);
            res["Ownership"] = get_owner_type.call(this, owner.ownershipCode);
            res["The owned"] = unit.unit ? getAddressAsShortString(unit.unit.address) : "-";
            res["Share"] = unit.share;
            rows.push(res);
          });
        }
      });

      return rows;
    },

    selectSorting(item) {
      if (item.active) {
        this.isDesc = !this.isDesc;
        return;
      }
      this.headers.forEach((itm) => (itm.active = false));
      item.active = true;
      this.isDesc = false;
      this.sortFunciton = item.sortFnc;
    },

    customSort(items, fnc, isDesc) {
      items.sort(fnc);
      if (!isDesc) {
        return items;
      } else {
        return items.reverse();
      }
    },

    sortByOwnership(a, b) {
      return this.sortByUnits(a, b) || this.sortByCompany(a, b) || sortByAddress(a.units[0].unit, b.units[0].unit);
    },

    sortByOwned(a, b) {
      if (!a.units) {
        return -1;
      } else if (!b.units) {
        return 1;
      } else if (a.units.length > b.units.length) {
        return -1;
      } else if (a.units.length < b.units.length) {
        return 1;
      } else {
        return sortByAddress(a.units[0].unit, b.units[0].unit);
      }
    },

    sortByOwner(a, b) {
      return this.sortByName(a, b);
    },

    sortByName(a, b) {
      if (!this.getOwnerName(a) || !this.getOwnerName(b)) return 0;
      return this.getOwnerName(a).localeCompare(this.getOwnerName(b));
    },

    sortByUnits(a, b) {
      if (!a.units) {
        return -1;
      } else if (!b.units) {
        return 1;
      } else return 0;
    },

    sortByCompany(a, b) {
      return !!a.cvrNumber === !!b.cvrNumber ? 0 : a.cvrNumber ? -1 : 1;
    },

    getOwnerName: getOwnerName,

    get_first_unit_by_search(owner) {
      let res = owner.units.find((unt) => {
        if (unt && unt.unit && unt.unit.address) {
          return getAddressAsShortString(unt.unit.address).toLowerCase().includes(this.searchTerm.toLowerCase());
        }
      });
      if (res && res.unit && res.unit.address) {
        return getAddressAsShortString(res.unit.address);
      }
      return getAddressAsShortString(owner.units[0].unit.address);
    },

    getOtherUnitDescription(cnt) {
      if (cnt > 1) {
        return this.$t("MORE_THAN_ONE_UNIT");
      } else {
        return this.$t("ONE_MORE_UNIT");
      }
    },

    amplitudeLogEvent() {
      var eventProperties = {
        address: this.$store.getters["search/searchTerm"],
        owners: this.property.owners.length,
        id: this.property.id,
      };

      this.$amplitude.track({
        event_type: "Download - owners",
        event_properties: eventProperties,
      });
    },

    getDefaultUnitAddress(owner) {
      if (owner.units && owner.units.length > 0) {
        return owner.units[0].unit?.address;
      } else {
        return undefined;
      }
    },
  },
};
